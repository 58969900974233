<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                Download information from
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none" />
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div class="row mb-3">
              <div class="col-sm-12">
                <a
                  class="btn btn-primary"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Download memoirs 2023"
                  @click="downloadMemoir()"
                >
                  <a
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary loop-loading p-0"
                    :class="{'d-none': !exportingMemoir}"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Loading export..."
                  ><i
                    data-feather="loader"
                  /></a>
                  <span
                    class="loop-loading"
                    :class="{'d-none': exportingMemoir}"
                  >
                    <i
                      data-feather="file"
                      class="me-25"
                    />
                  </span>

                  {{ !exportingMemoir ? 'Download memoirs 2023' : 'Downloading memoirs...' }}
                </a>
                <a
                  class="btn btn-primary ms-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Download PDF memoirs highlights"
                  @click="downloadAwardeds()"
                >
                  <a
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary loop-loading p-0"
                    :class="{'d-none': !exportingAwardeds}"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Loading export..."
                  ><i
                    data-feather="loader"
                  /></a>
                  <span
                    class="loop-loading"
                    :class="{'d-none': exportingAwardeds}"
                  >
                    <i
                      data-feather="file"
                      class="me-25"
                    />
                  </span>

                  {{ !exportingAwardeds ? 'Download awardeds memoirs 2023' : 'Downloading awardeds memoirs 2023...' }}
                </a>
                <a
                  class="btn btn-primary ms-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Download highlights 2023"
                  @click="downloadHighlights()"
                >
                  <a
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary loop-loading p-0"
                    :class="{'d-none': !exportingHighlights}"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Loading export..."
                  ><i
                    data-feather="loader"
                  /></a>
                  <span
                    class="loop-loading"
                    :class="{'d-none': exportingHighlights}"
                  >
                    <i
                      data-feather="file"
                      class="me-25"
                    />
                  </span>

                  {{ !exportingHighlights ? 'Download highlights 2023' : 'Downloading highlights 2023...' }}
                </a>
                <!-- <a
                  class="btn btn-primary ms-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Download Spin off 2023"
                  @click="downloadSpinOffs()"
                >
                  <a
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary loop-loading p-0"
                    :class="{'d-none': !exportingSpinOffs}"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Loading export..."
                  ><i
                    data-feather="loader"
                  /></a>
                  <span
                    class="loop-loading"
                    :class="{'d-none': exportingSpinOffs}"
                  >
                    <i
                      data-feather="file"
                      class="me-25"
                    />
                  </span>

                  {{ !exportingSpinOffs ? 'Download Spin off 2023' : 'Downloading Spin off 2023...' }}
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BTab,
  },
  data() {
    return {
      exportingAwardeds: false,
      exportingMemoir: false,
      exportingHighlights: false,
      exportingSpinOffs: false,
    }
  },
  async mounted() {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    async downloadAwardeds() {
      this.exportingAwardeds = true
      await this.$store.dispatch('memoirsProfile/exportAwardedCSV', {
        year: 2023, // hardcoded
      })
      this.exportingAwardeds = false
    },
    async downloadMemoir() {
      this.exportingMemoir = true
      await this.$store.dispatch('memoirsProfile/exportCSV', {
        year: 2023, // hardcoded
      })
      this.exportingMemoir = false
    },
    async downloadHighlights() {
      this.exportingHighlights = true
      await this.$store.dispatch('memoirsProfile/exportCSV', {
        year: 2023, // hardcoded
      })
      this.exportingHighlights = false
    },
    async downloadSpinOffs() {
      this.exportingSpinOffs = true
      await this.$store.dispatch('memoirsProfile/exportCSV', {
        year: 2023, // hardcoded
      })
      this.exportingSpinOffs = false
    },
  },
}
</script>
